<template>
      <div>
            <app-collapse class="mb-2">
                  <app-collapse-item title="Detalle credito reclamado">
                        <b-row>
                              <b-col sm="3" v-for="(item, index) in concept" :key="index">
                                    <b-card
                                          style="border: 1px solid #28c76f; width: 100%; height: auto; margin-bottom: 14px; border-radius: 5px;">
                                          <p class="text-center">{{ item.label }}</p>
                                          <p class="text-center">{{ item.value }}</p>
                                    </b-card>
                              </b-col>
                        </b-row>
                  </app-collapse-item>
            </app-collapse>
            <b-card no-body class="mt-1 mb-2" v-if="aditionalInfo007.state">
              <b-row class="p-2">
                <b-col sm="3">
                  <b-form-checkbox
                    v-model="aditionalInfo007.is_covid"
                    @change="validateAndChangeMarking('is_covid')"
                  >
                    Covid
                  </b-form-checkbox>
                </b-col>
                <b-col sm="3">
                  <b-form-checkbox
                    v-model="aditionalInfo007.is_capita"
                    @change="validateAndChangeMarking('is_capita')"
                  >
                    Capita
                  </b-form-checkbox>
                </b-col>
                <b-col sm="3">
                  <b-form-checkbox
                    v-model="aditionalInfo007.is_non_pbs"
                    @change="validateAndChangeMarking('is_non_pbs')"
                  >
                    No PBS (Mipres)
                  </b-form-checkbox>
                </b-col>
                <b-col sm="3">
                  <b-form-checkbox
                    v-model="aditionalInfo007.is_prescription"
                    @change="validateAndChangeMarking('is_prescription')"
                  >
                    Prescripción
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-card>
            <b-card no-body class="mt-1 mb-2" v-if="componentState">
                  <b-row class="p-2">

                        <b-col sm="3">
                              <b-card
                                    style="border: 1px solid #28c76f; width: 100%; height: 100px; margin-bottom: 14px; border-radius: 5px;">
                                    <p class="text-center">Valor reclamado</p>
                                    <p class="text-center">{{ concept.reclamation_value.value }}</p>
                              </b-card>
                        </b-col>
                        <b-col sm="3">
                              <b-card
                                    style="border: 1px solid #28c76f; width: 100%; height: 100px; margin-bottom: 14px; border-radius: 5px;">
                                    <p class="text-center">Valor glosado</p>
                                    <p class="text-center">{{ concept.glosa_value.value }}</p>
                              </b-card>
                        </b-col>
                        <b-col sm="3">
                              <b-card
                                    style="border: 1px solid #28c76f; width: 100%; height: 100px; margin-bottom: 14px; border-radius: 5px;">
                                    <p class="text-center">Valor aprobado</p>
                                    <p class="text-center">{{ concept.approved_value.value }}</p>
                              </b-card>
                        </b-col>
                        <b-col sm="3">
                              <b-card
                                    style="border: 1px solid #28c76f; width: 100%; height: 100px; margin-bottom: 14px; border-radius: 5px;">
                                    <p class="text-center">Glosas</p>
                                    <p class="text-center">{{ concept.glosas.value }}</p>
                              </b-card>
                        </b-col>
                  </b-row>
            </b-card>
            <b-card>
                  <template>
                        <b-row>
                              <b-col sm="12" class="text-right mb-3">
                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger" class="mr-2"
                                          @click="confirmButtonColor2">
                                          Finalizar
                                    </b-button>
                                    <b-button id="show-btn" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                          variant="outline-primary" @click="showModal('save', null)" v-b-modal.modal-xl>
                                          Aplicar glosa
                                    </b-button>
                              </b-col>
                        </b-row>
                        <b-row>
                              <b-col cols="12">
                                    <vue-good-table :columns="fields" :rows="rows" :search-options="{
                                          enabled: true,
                                          externalQuery: searchTerm
                                    }" :pagination-options="{
      enabled: true,
      perPage: pageLength
}">
                                          <template slot="table-row" slot-scope="props">
                                                <span v-if="props.column.field === 'actions'" class="text-nowrap">
                                                      <b-button id="show-btn" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                            variant="primary" class="btn-icon rounded-circle mr-1">
                                                            <feather-icon icon="EditIcon" size="18"
                                                                  @click="showModal('update', props.row)" />
                                                      </b-button>
                                                      <b-button id="show-btn" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                            class="btn-icon rounded-circle" variant="danger"
                                                            @click="confirmButtonColor(props.row.id)">
                                                            <feather-icon icon="TrashIcon" size="18" />
                                                      </b-button>
                                                </span>
                                          </template>
                                          <!-- pagination -->
                                          <template slot="pagination-bottom" slot-scope="props">
                                                <div class="d-flex justify-content-between flex-wrap">
                                                      <div class="d-flex align-items-center mb-0 mt-1">
                                                            <span class="text-nowrap">
                                                                  Mostrando
                                                            </span>
                                                            <b-form-select v-model="pageLength" :options="['10', '50', '100']"
                                                                  class="mx-1"
                                                                  @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                                            <span class="text-nowrap "> {{ props.total }} registros
                                                                  encontrados </span>
                                                      </div>

                                                      <div>
                                                            <b-pagination :value="1" :total-rows="props.total"
                                                                  :per-page="pageLength" first-number last-number
                                                                  align="right" prev-class="prev-item" next-class="next-item"
                                                                  class="mt-1 mb-0"
                                                                  @input="(value) => props.pageChanged({ currentPage: value })">
                                                                  <template #prev-text>
                                                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                                                  </template>
                                                                  <template #next-text>
                                                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                                                  </template>
                                                            </b-pagination>
                                                      </div>
                                                </div>
                                          </template>
                                    </vue-good-table>
                              </b-col>
                        </b-row>
                  </template>
            </b-card>
            <b-modal ref="my-modal" hide-footer title="Aplicar glosa" size="lg">
                  <validation-observer ref="simpleRules">
                        <div class="d-block">
                              <b-form class="">
                                    <validation-provider #default="{ errors }" name="Glosa" rules="required">
                                          <b-col sm="12" class="mb-3">
                                                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                                                      :options="glosas" v-model="form.specific_code_glosa_id"
                                                      :reduce="val => val.value" placeholder="Selccionar" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                          </b-col>
                                    </validation-provider>
                              </b-form>
                              <b-col sm="12" class="mb-3">
                                    <b-form-input v-model="form.value" placeholder="Valor Glosa" type="number" />
                                    <small class="text-danger">{{ errorValue }}</small>
                              </b-col>
                              <b-col sm="12" class="mb-3">
                                    <b-form-textarea v-model="form.comment" placeholder="Observación" rows="3" />
                              </b-col>
                              <b-col sm="12" class="mb-3">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                                          @click="validationForm()" v-if="save">
                                          Guardar
                                    </b-button>
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                                          @click="validationForm(idModal)" v-if="update">
                                          Editar
                                    </b-button>
                              </b-col>
                        </div>
                  </validation-observer>
            </b-modal>
      </div>
</template>
        
<script>
extend('required', {
      ...required,
      message: 'El campo {_field_} es obligatorio'
});
import { extend } from 'vee-validate'
import {
      BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BButton, BRow, BCol, BTable, BInputGroup, BInputGroupAppend, BModal, BFormTextarea, BForm, ListGroupPlugin, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VueGoodTable } from 'vue-good-table'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
export default {
      components: {
            VueGoodTable,
            BAvatar,
            BBadge,
            BPagination,
            BFormGroup,
            BFormInput,
            BFormSelect,
            BDropdown,
            BDropdownItem,
            BCard,
            BButton,
            BRow,
            BCol,
            BTable,
            BInputGroup,
            BInputGroupAppend,
            BModal,
            vSelect,
            BFormTextarea,
            AppCollapse,
            AppCollapseItem,
            ValidationProvider,
            ValidationObserver,
            BForm,
            BFormCheckbox,
      },
      data() {
            return {
                  aditionalInfo007: {
                    state: false,
                    is_covid: false,
                    is_capita: false,
                    is_non_pbs: false,
                    is_prescription: false,
                  },
                  idModal: null,
                  required: null,
                  form: {
                        specific_code_glosa_id: null,
                        value: null,
                        comment: null,
                        concept_id: null,
                        assignment_id: null
                  },
                  glosas: null,
                  option: [],
                  perPage: 5,
                  pageOptions: [10, 50, 100],
                  totalRows: 1,
                  currentPage: 1,
                  sortBy: '',
                  sortDesc: false,
                  sortDirection: 'asc',
                  filter: null,
                  filterOn: [],
                  fields: [
                        {
                              label: 'Código Glosa',
                              field: 'specific_code_glosa',
                        },
                        {
                              label: 'Descriptor glosa',
                              field: 'description_glosa',
                        },
                        {
                              label: 'Observación',
                              field: 'comment',
                        },
                        {
                              label: 'Valor glosa',
                              field: 'value',
                        },
                        {
                              label: 'Analista',
                              field: 'user',
                        },
                        {
                              label: 'Fecha creación',
                              field: 'created_at',
                        },
                        {
                              label: 'Acciones',
                              field: 'actions',
                        }
                  ],
                  searchTerm: '',
                  pageLength: 10,
                  rows: [],
                  concept: null,
                  id: this.$route.params && this.$route.params.id,
                  componentState: false,
                  errorValue: null,
                  errorValueActive: null,
                  update: null,
                  save: null,
                  selectedglosas: [],
                  glosasRemoved: []
            }
      },
      methods: {
            confirmButtonColor2(id) {
                  this.$swal({
                        title: '¿Estas seguro?',
                        text: "Finalizar Auditoría",
                        icon: 'warning',
                        showCancelButton: true,
                        cancelButtonText: 'Cancelar',
                        confirmButtonText: 'Si, Finalizar',
                        customClass: {
                              confirmButton: 'btn btn-success',
                              cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                  }).then(result => {
                        if (result.value) {
                              this.form.assignment_id = this.concept.assignment_id.value
                              this.$http.post('/auditory/finish-assignment', { assignment_id: this.form.assignment_id }).then((response) => {
                                    try {
                                          if (response.data.code == 200) {
                                                this.$swal({
                                                      title: response.data.message,
                                                      icon: 'success',
                                                      customClass: {
                                                            confirmButton: 'btn btn-success',
                                                      },
                                                      buttonsStyling: false,
                                                });
                                                window.history.back();
                                                this.form = {
                                                      specific_code_glosa_id: null,
                                                      value: null,
                                                      comment: null,
                                                      concept_id: null,
                                                      assignment_id: null
                                                }
                                          }
                                          if (response.data.code == 500) {
                                                this.$swal({
                                                      title: response.data.message,
                                                      icon: 'error',
                                                      customClass: {
                                                            confirmButton: 'btn btn-success',
                                                      },
                                                      buttonsStyling: false,
                                                });
                                          }
                                    } catch (error) {

                                    }
                              })
                              this.$swal({
                                    icon: 'success',
                                    title: 'Buen trabajo',
                                    text: 'Finalizaste esta auditoría con éxito',
                                    customClass: {
                                          confirmButton: 'btn btn-success',
                                    },
                              })
                        } else if (result.dismiss === 'cancel') {
                              this.$swal({
                                    title: 'Cancelar',
                                    icon: 'error',
                                    customClass: {
                                          confirmButton: 'btn btn-success',
                                    },
                              })
                        }
                  })
            },
            validationForm(id) {
                  this.$refs.simpleRules.validate().then(success => {
                        if (success) {
                              if (this.errorValueActive != 'error') {
                                    if (!id) {
                                          this.storeGlosa()
                                    } else {
                                          this.updateGlosa(id)
                                    }
                              }

                        }
                  })
            },
            storeGlosa() {
                  this.form.concept_id = this.concept.concept_id.value
                  this.form.assignment_id = this.concept.assignment_id.value
                  this.$http.post('/auditory/save-glosa', this.form).then((response) => {
                        try {
                              if (response.data.code == 200) {
                                    this.$swal({
                                          title: response.data.message,
                                          icon: 'success',
                                          customClass: {
                                                confirmButton: 'btn btn-success',
                                          },
                                          buttonsStyling: false,
                                    });
                                    this.$refs['my-modal'].hide()
                                    this.fetchConceptId()
                                    this.selectCodesGlosas()
                                    this.form = {
                                          specific_code_glosa_id: null,
                                          value: null,
                                          comment: null,
                                          concept_id: null,
                                          assignment_id: null
                                    }
                              }
                              if (response.data.code == 500) {
                                    this.$swal({
                                          title: response.data.message,
                                          icon: 'error',
                                          customClass: {
                                                confirmButton: 'btn btn-success',
                                          },
                                          buttonsStyling: false,
                                    });
                              }
                        } catch (error) {

                        }
                  })
            },
            updateGlosa(id) {
                  this.form.concept_id = this.concept.concept_id.value
                  this.form.assignment_id = this.concept.assignment_id.value
                  this.$http.post('/auditory/edit-glosa/' + id, this.form).then((response) => {
                        try {
                              if (response.data.code == 200) {
                                    this.$swal({
                                          title: response.data.message,
                                          icon: 'success',
                                          customClass: {
                                                confirmButton: 'btn btn-success',
                                          },
                                          buttonsStyling: false,
                                    });
                                    this.$refs['my-modal'].hide()
                                    this.fetchConceptId()
                                    this.form = {
                                          specific_code_glosa_id: null,
                                          value: null,
                                          comment: null,
                                          concept_id: null,
                                          assignment_id: null
                                    }
                              }
                              if (response.data.code == 500) {
                                    this.$swal({
                                          title: response.data.message,
                                          icon: 'error',
                                          customClass: {
                                                confirmButton: 'btn btn-success',
                                          },
                                          buttonsStyling: false,
                                    });
                              }
                        } catch (error) {

                        }
                  })
            },
            deleteGlosa(id) {
                  this.$http.post('/auditory/delete-glosa/' + id).then((response) => {
                        try {
                              if (response.data.code == 200) {
                                    this.$swal({
                                          title: response.data.message,
                                          icon: 'success',
                                          customClass: {
                                                confirmButton: 'btn btn-success',
                                          },
                                          buttonsStyling: false,
                                    });
                                    this.fetchConceptId()
                              }
                              if (response.data.code == 500) {
                                    this.$swal({
                                          title: response.data.message,
                                          icon: 'error',
                                          customClass: {
                                                confirmButton: 'btn btn-success',
                                          },
                                          buttonsStyling: false,
                                    });
                              }
                        } catch (error) {

                        }
                  })
            },
            showModal(method, data) {
                  if (method == 'update' && data) {
                        this.$refs['my-modal'].show()
                        this.form.value = data.value.replace(/\./g, '')
                        this.form.comment = data.comment
                        this.form.specific_code_glosa_id = data.specific_code_glosa_id
                        this.idModal = data.id
                        this.save = false
                        this.update = true
                  }
                  if (method == 'save' && data == null) {
                        this.save = true
                        this.update = false
                        this.form = {
                              specific_code_glosa_id: null,
                              value: null,
                              comment: null,
                              concept_id: null,
                              assignment_id: null
                        }
                        this.$refs['my-modal'].show()
                  }
            },
            fetchConceptId() {
                  this.$http.get('/auditory/concept/' + this.id).then((response) => {
                        this.concept = response.data.row
                        this.rows = response.data.glosas
                        // this.spliceGlosas(response.data.selectedGlosas)
                        this.componentState = true
                        if(response.data.row.reclamation_type_id.value === 7){
                          this.aditional007Info(response.data.row.conceptable_id.value)
                        }
                  })
            },
            selectCodesGlosas() {
                  this.$http.get('/auditory/get-glosas-codes').then((response) => {
                        this.glosas = response.data.glosas_codes
                  })
            },
            valueGlosa(val) {
                  if (this.concept.reclamation_value_credit.value < val) {
                        this.errorValue = 'El valor no puede ser mayor al valor reclamado'
                        this.errorValueActive = 'error'
                  } else {
                        this.errorValue = null
                        this.errorValueActive = null
                  }
            },
            confirmButtonColor(id) {
                  this.$swal({
                        title: 'Esta seguro de eliminar?',
                        text: "Este cambio no se puede deshacer!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Eliminar!',
                        customClass: {
                              confirmButton: 'btn btn-primary',
                              cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                  }).then(result => {
                        if (result.value) {
                              this.deleteGlosa(id)
                        } else if (result.dismiss === 'cancel') {
                              this.$swal({
                                    title: 'Cancelado',
                                    icon: 'error',
                                    customClass: {
                                          confirmButton: 'btn btn-success',
                                    },
                              })
                        }
                  })
            },
            aditional007Info(invoiceId) {
                
                this.$http.get('/auditory/get-markings/' + invoiceId).then((response) => {
                      this.aditionalInfo007.is_covid = response.data.markings.is_covid === 1 ? true : false
                      this.aditionalInfo007.is_capita = response.data.markings.is_capita === 1 ? true : false
                      this.aditionalInfo007.is_non_pbs = response.data.markings.is_non_pbs === 1 ? true : false
                      this.aditionalInfo007.is_prescription = response.data.markings.is_prescription === 1 ? true : false
                      this.aditionalInfo007.state = true
                })
            },
            validateAndChangeMarking(marking) {
              const { is_covid, is_capita, is_non_pbs } = this.aditionalInfo007;
              if ([is_covid, is_capita, is_non_pbs].filter(Boolean).length > 1) {
                this.aditional007Info(this.concept.conceptable_id.value)
                this.$swal({
                  title: 'Error',
                  text: 'Solo se puede marcar uno de los 3 campos, Covid, Capita o No PBS, si desea marcar este debe desmarcar antes el otro. El campo prescripción no tiene restricción',
                  icon: 'error',
                });
                return;
              }
              this.changeMarking(marking);
            },
            changeMarking(marking) {
              const formData = new FormData();
              formData.append('marking', marking);
              formData.append('value', this.aditionalInfo007[marking]);
              this.$http.post('/auditory/change-marking/' + this.concept.conceptable_id.value, formData).then((response) => {
                
                try {
                      if (response.data.code == 200) {
                            this.$swal({
                                  title: response.data.message,
                                  icon: 'success',
                                  customClass: {
                                        confirmButton: 'btn btn-success',
                                  },
                                  buttonsStyling: false,
                            });
                            this.aditional007Info(this.concept.conceptable_id.value)
                      }
                      if (response.data.code == 500) {
                            this.$swal({
                                  title: response.data.message,
                                  icon: 'error',
                                  customClass: {
                                        confirmButton: 'btn btn-success',
                                  },
                                  buttonsStyling: false,
                            });
                            this.aditional007Info(this.concept.conceptable_id.value)
                      }
                } catch (error) {

                }
                
              })
            },
      },
      created() {
            this.fetchConceptId()
            this.selectCodesGlosas()
      },
      directives: {
            Ripple,
      },
      watch: {
            'form.value'(val) {
                  this.valueGlosa(val)
            },
      }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-good-table.scss";
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>